import Api from './Api'

export default {
    get(id) {
        return Api().get(`mercadopago/card/getCards?id=${id}`);
    },
    getOne(id, customer_id) {
        return Api().get(`mercadopago/card/getCard?id=${id}&customer_id=${customer_id}`);
    },
    create(data) {
        return Api().post(`mercadopago/card/`, data);
    },
    update(data) {
        return Api().put(`mercadopago/card/`, data);
    },
    delete(id, customer_id) {
        return Api().delete(`mercadopago/card?card_id=${id}&customer_id=${customer_id}`);
    }
}