// EXEMPLO DE MODELO DE SERVICES

// lowercase_singular = exemplo

import Api from './Api'

export default {
    findById(id) {
        return Api().get(`mercadopago/user/getUser?id=${id}`);
    },
    findByEmail(email) {
        return Api().get(`mercadopago/user/search?email=${email}`);
    },
    create(data) {
        return Api().post(`mercadopago/user/`, data);
    },
    update(data) {
        return Api().put(`mercadopago/user/${data.id}`, data);
    }
}