import CategoryService from '../../services/CategoryService'

const state = () => ({
    category: null,
    categories:  [],
    categoriesPeriods:  [],
    // categories: localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : [],
    postsLoading: false
})
  
const mutations = {
    SET_CATEGORY: (state, payload) => {
        state.category = payload;
    },
    SET_CATEGORIES: (state, payload) => {
        state.categories = payload;
        localStorage.setItem('categories', JSON.stringify(payload));
    },
    SET_CATEGORIES_PERIODS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.categoriesPeriods = payload.items
        }else{
            state.categoriesPeriods = [...state.categoriesPeriods, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_CATEGORIES_LOADING: (state, payload) => {
        state.categoriesLoading = payload;
    }
}

const actions = {
    async fetchMyCategories({commit}){
        try {
            commit('SET_CATEGORIES_LOADING', true);
            const response = await CategoryService.getAll();
            commit('SET_CATEGORIES', response.data.itens);
            commit('SET_CATEGORIES_LOADING', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchMyCategoriesByPeriods({commit}){
        try {
            commit('SET_CATEGORIES_LOADING', true);
            const response = await CategoryService.getAllByPeriods();
            commit('SET_CATEGORIES_PERIODS', response.data.itens);
            commit('SET_CATEGORIES_LOADING', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCategory({commit}, slug){
        try {
            commit('SET_CATEGORIES_LOADING', true);
            const response = await CategoryService.getOne(slug);
            commit('SET_CATEGORY', response.data);
            commit('SET_CATEGORIES_LOADING', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getCategory: state => state.category,
    getCategoies: state => state.categories,
    getCategoiesLoading: state => state.categoriesLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};