import User_MPService from '../../services/UserMPService'

const state = () => ({
    loadingUsers_MP: false,
    user_mp: null,
    users_mp: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_user_mp: false
})
  
const mutations = {
    SET_USER_MP: (state, payload) => {
        state.user_mp = payload
    },
    SET_LOADING_USERS_MP: (state, payload) => {
        state.loadingUsers_MP = payload
    },
    SET_SHOW_MODAL_USER_MP: (state, payload) => {
        state.show_modal_new_user_mp = payload
    },
}

const actions = {
    async findCustomer_id({commit, state, dispatch}){
        try{
            commit('SET_LOADING_USERS_MP', true)
            if (!state.user_mp || state.user_mp == null || !state.user_mp.id) {
                await dispatch('findUserMPByEmail');
            }
            if (!state.user_mp || state.user_mp == null || !state.user_mp.id) {
                await dispatch('createUser_MP');
            }
            commit('SET_LOADING_USERS_MP', false);
            if (state.user_mp && state.user_mp !== null && state.user_mp.id) {
                return state.user_mp.id;
            }
            return false;
        } catch (error) {
            commit('SET_LOADING_USERS_MP', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response ? error.response.data.message : 'Erro desconhecido'
            });
        }
    },
    async findUserMPById({commit, state}, id){
        try{
            commit('SET_LOADING_USERS_MP', true)
            const response = await User_MPService.findById(id);
            commit('SET_LOADING_USERS_MP', false)
            if (state.user_mp && state.user_mp !== null && state.user_mp.id) {
                commit('SET_USER_MP', response.data)
                return state.user_mp;
            }
            return false;
        } catch (error) {
            commit('SET_LOADING_USERS_MP', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response ? error.response.data.message : 'Erro desconhecido'
            });
        }
    },
    async findUserMPByEmail({commit, rootState}){
        try{
            commit('SET_LOADING_USERS_MP', true)
            const response = await User_MPService.findByEmail(rootState.user.user.email);
            if (response.data.results && response.data.results.length > 0) {
                const userMP = response.data.results[0];
                const cards = userMP.cards;
                await commit('SET_USER_MP', userMP);
                commit('SET_CARDS', cards);
            }
            commit('SET_LOADING_USERS_MP', false);
        } catch (error) {
            commit('SET_LOADING_USERS_MP', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response ? error.response.data.message : 'Erro desconhecido'
            });
        }
    },
    async createUser_MP({commit, rootState}) {
        try {
            const user = rootState.user.user;
            const userDoc = user.User_Docs.find(doc => doc.type === 'cpf');
            const userPhone = user.UserPhones[0];
            const userAddress = user.UserAddresses[0];
            
            if(!userAddress){
                commit('SET_SHOW_MODAL_ADDRESS', true)
                commit('SET_ALERT', {
                    heading: 'alert',
                    message: 'Necessário Cadastrar um Endereço'
                });
                return 
            }
            const data = {
                email: user.email,
                first_name: user.firstName,
                last_name: user.lastName,
                description: `extRef_${process.env.VUE_APP_TITLE}_${user.id}`,
                phone: {
                    area_code: userPhone ? userPhone.number.slice(1, 3) : "01",
                    number: userPhone ? userPhone.number.replace(/\D/g, '').slice(2) : "987654321"
                },
                default_address: `Home_${userAddress.id}`,
                identification: {
                    type: "CPF",
                    number: userDoc ? userDoc.number : "12345678909"
                },
                address: {
                    id: userAddress ? userAddress.id : "1",
                    zip_code: userAddress ? userAddress.zip.replace(/\D/g, '') : "17012110",
                    street_name: userAddress ? userAddress.street : "Rua Gustavo Maciel",
                    street_number: userAddress ? userAddress.number : 2240,
                    city: {
                        name: userAddress ? userAddress.city : "Bauru"
                    }
                }
            }
            commit('SET_LOADING_USERS_MP', true);
            const response = await User_MPService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            await commit('SET_USER_MP', response.data.item);
            commit('SET_SHOW_MODAL_USER_MP', false);
            commit('SET_LOADING_USERS_MP', false);
        } catch (error) {
            commit('SET_LOADING_USERS_MP', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response ? error.response.data.message : 'Erro desconhecido'
            });
        }
    },    
    async updateUser_MP({commit}, data){
        try{
            commit('SET_LOADING_USERS_MP', true)
            const response = await User_MPService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_USERS_MP', false)
        } catch (error) {
            commit('SET_LOADING_USERS_MP', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response ? error.response.data.message : 'Erro desconhecido'
            });
        }
    },
}

const getters = {
    getUser_MP: state => state.user_mp,
    getLoadingUser_MP: state => state.loadingUsers_MP,
    getShowModalNewUser_MP: state => state.show_modal_new_user_mp
}

export default {
    state,
    getters,
    mutations,
    actions
};