<template>
    <footer aria-label="Site Footer" class="bg-white border-t">
    <div class="mx-auto max-w-screen-xl py-5 px-4 sm:px-6 lg:px-8">
        <p class="text-[10px] text-gray-500">
            Copyright © {{ new Date().getFullYear() }} Unimagem. Todos os direitos reservados.
        </p>
    </div>
    </footer>
</template>

<script>
    export default {
        name: 'FootterComponnet',
        data: () => {
            return {
            }
        },
    }
</script>