const state = () => ({
    isMenuOpen: false,
    authenticateBeforeCheckout: true,
    hideScrollBar: false,
    alerts: [],
    currency: 'R$',
    storeName: process.env.VUE_APP_TITLE,
    showModalConfirmation: false,
    notificationAnuidade: localStorage.getItem('n_Anuidade') || true,
    showModalTerms: false,
})
  
const mutations = {
    TOGGLE_MENU(state, payload) {
        state.hideScrollBar = payload
        state.isMenuOpen = payload
    },
    SET_ALERT(state, alert){
        if(alert && alert.message != "Sessão expirada") {
            state.alerts.unshift(alert);
            setTimeout(() => {
                state.alerts.pop();
            }, 5000);
        }
    },
    TOGGLE_NOTIFICATION_ANUIDADE(state) {
        localStorage.setItem('n_Anuidade', "visualizado");
        state.notificationAnuidade = "visualizado"
    },
    SET_SHOW_MODAL_CONFIRMATION: (state, payload) => {
        state.showModalConfirmation = payload;
    },
    SET_SHOW_MODAL_TERMS(state, boolean){
        state.showModalTerms= boolean
    },
}

const getters = {
    getIsMenuOpen: state => state.isMenuOpen,
    getAlerts: state => state.alerts,
    getShowModalConfirmation: state => state.showModalConfirmation,
    getNotificationAnuidade: state => state.notificationAnuidade,
    getShowModalTerms: state => state.showModalTerms,
}

export default {
    state,
    getters,
    mutations
};