import PaymentService from '../../services/PaymentService'
import RefundService from '../../services/RefundService'

const state = () => ({
    payment: null,
    currentPayment: null,
    selectedTypePayment: false,
    paymentLoading: false,
    total: 0.00,
    paymentCurrentStep: {
        step: 1,
        methodsPayment: null
    },
    show_modal_new_payment: false,
    show_modal_receipt: false
})
  
const mutations = {
    SET_TYPE_PAYMENT: (state, type) => {
        state.selectedTypePayment = type;
    },
    SET_PAYMENT: (state, payment) => {
        state.payment = payment;
    },
    SET_CURRENT_PAYMENT: (state, data) => {
        state.currentPayment = data;
    },
    UPDATE_PAYMENT: (state, payment) => {
        state.payment = payment;
    },
    SET_LOADING_PAYMENT: (state, payload) => {
        state.paymentLoading = payload
    },
    SET_TOTAL: (state, total) => {
        state.total = total.toFixed(2)
    },
    SET_STEP_PAYMENT: (state, stepCurrent) => {
        state.paymentCurrentStep = stepCurrent
    },
    SET_SHOW_MODAL_NEW_PAYMENT: (state, payload) => {
        state.show_modal_new_payment = payload
    },
    SET_SHOW_MODAL_RECEIPT: (state, payload) => {
        state.show_modal_receipt = payload
    },
}

const actions = {
    async fetchPayment({ commit }, paymentId){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const response  = await PaymentService.getOneByTransaction_id(paymentId);
            commit('SET_PAYMENT', response.data);
            commit('SET_LOADING_PAYMENT', false);
        } catch (error) {
            console.log(error)
            commit('SET_PAYMENT', null);
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async getValueToPay({ commit }, {plans, cupom}){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const response  = await PaymentService.getValuePayment({plans, cupom});
            commit('SET_TOTAL', response.data);
            commit('SET_LOADING_PAYMENT', false);
            return response.data;
        } catch (error) {
            console.log(error)
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPaymentPix({rootState, commit}, body){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const user = rootState.user.user;

            body.external_reference = `extRef_${process.env.VUE_APP_TITLE}_${body.description}`;
            body.payment_method_id = `pix`;
            body.payer = {
                first_name: user.firstName,
                last_name: user.last_name,
                email: user.email,
            };
            body.notification_url = process.env.VUE_APP_NOTIFICATION_URL;

            const response = await PaymentService.createPaymentPix(body);
            const data = response.data
            const PlanId = rootState.plan.plan.plan_id
            const CuponId = (rootState.cupom && rootState.cupom.cupom && rootState.cupom.cupom.id) ? rootState.cupom.cupom.id : null

            await PaymentService.savePaymentPix({ orderPayment: body, statusPayment: data, PlanId, CuponId });
        
            commit('SET_PAYMENT', data);
            commit('SET_LOADING_PAYMENT', false);
        } catch (error) {
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPaymentCard({commit, dispatch, rootState}, { type = 'payment', transaction_amount, payment_method_id, issuer_id, token, installments, description, payer, deviceId, items }){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const infoUser = await dispatch('findUserMPById', payer.id);

            const body = {
                transaction_amount,
                payment_method_id,
                description,
                statement_descriptor: description,
                installments,
                token,
                issuer_id,
                external_reference: `extRef_${process.env.VUE_APP_TITLE}_${description}`,
                notification_url: process.env.VUE_APP_NOTIFICATION_URL,
                payer,
                deviceId 
            }

            body.additional_info = {
                payer: {
                    first_name: infoUser.first_name,
                    last_name: infoUser.last_name,
                    address: {
                        zip_code: infoUser.address.zip_code,
                        street_name: infoUser.address.street_name,
                        street_number: infoUser.address.street_number
                    },
                    phone: infoUser.phone,
                    registration_date: infoUser.date_created
                },
                items: items.map(item => ({
                    "id": item.plan_id.toString(),
                    "title": item.name,
                    "description": `Pagamento do Plano d${process.env.VUE_APP_ARTIGO} ${process.env.VUE_APP_TITLE}`,
                    "picture_url": `${process.env.VUE_APP_URL}/img/logo.png`,
                    "category_id": item.plan_category_id.toString(),
                    "quantity": 1,
                    "unit_price": item.price,
                    "type": "Plans",
                    "event_date": new Date().toISOString(),
                    "warranty": false,
                    "category_descriptor": {
                        "passenger": {},
                        "route": {}
                    }
                })),
            };

            const response = await PaymentService.createPaymentCard(body);
            const data = response.data
           
            const CuponId = (rootState.cupom && rootState.cupom.cupom && rootState.cupom.cupom.id) ? rootState.cupom.cupom.id : null
            const PlanId = (rootState.plan && rootState.plan.plan && rootState.plan.plan.plan_id) ? rootState.plan.plan.plan_id : null

            await PaymentService.savePaymentCredit({ orderPayment: body, statusPayment: data, PlanId, CuponId });
            
            if(type === 'payment'){
                dispatch('finishPayment', data.id)
            }
            commit('SET_LOADING_PAYMENT', false);
            return data
        } catch (error) {
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async finishPayment({ commit, dispatch, rootState }, transaction_id){
        try {
            rootState.plan.plan.payment_transaction_id = transaction_id;
            commit('SET_SHOW_MODAL_NEW_PAYMENT', false);
            commit('SET_SHOW_MODAL_RECEIPT', true);

            dispatch('fetchPlans', { keyword: '' })
            dispatch('fetchPlanPending', { keyword: '' })

            commit('SET_STEP_PAYMENT', { step: 1, methodsPayment: null});
        } catch (error) {
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async refundMyPayment({ commit, dispatch }, paymentId){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const response  = await RefundService.createRefundPayment(paymentId);
            const data = response.data;
            dispatch('setWebhookPayment', paymentId)
            commit('SET_LOADING_PAYMENT', false);
            return data;
        } catch (error) {
            console.log(error)
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async verifyPaymentPix({ dispatch }, paymentId){
        try {
            const response  = await PaymentService.getOneByTransaction_id(paymentId);
            const { status } = response.data;

            if(status == 'approved'){
                await dispatch('finishPayment', paymentId)
            }
        } catch (error) {
            console.log(error)
        }
    },
    async setWebhookPayment({ commit }, paymentId){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const body = {
                type: 'payment', 
                data:{ id: paymentId }
            }
            const response  = await PaymentService.setWebhookPayment(body);
            commit('SET_PAYMENT', response.data);
            commit('SET_LOADING_PAYMENT', false);
        } catch (error) {
            console.log(error)
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPayment: state => state.payment,
    getLoadingPayment: state => state.paymentLoading,
    getCurrentPayment: state => state.currentPayment,
    getSelectedTypePayment: state => state.selectedTypePayment,
    getTotal: state => state.total,
    getPaymentCurrentStep: state => state.paymentCurrentStep,
    getShowModalNewPayment: state => state.show_modal_new_payment,
    getShowModalReceipt: state => state.show_modal_receipt
}

export default {
    state,
    getters,
    mutations,
    actions
};