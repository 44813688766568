// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import password from './modules/password';
import email from './modules/email';
import navbar from './modules/navbar';
import posts from './modules/posts';
import category from './modules/category';
import address from './modules/address';
import cropper_image from './modules/cropper_image';
import document from './modules/document';
import comercial from './modules/comercial';
import plan from './modules/plan';
import payment from './modules/payment';
import card from './modules/card';
import user_mp from './modules/user_mp';
import subscription from './modules/subscription';
import cupom from './modules/cupom';

// Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        password,
        email,
        navbar,
        posts,
        category,
        address,
        cropper_image,
        document,
        comercial,
        plan,
        payment,
        card,
        user_mp,
        subscription,
        cupom
    }
})
