import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`mercadopago/subscription?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getMyCurrent() {
        return Api().get(`subscription/my-current`);
    },
    create(data) {
        return Api().post(`mercadopago/subscription`, data);
    },
    update(data) {
        return Api().put(`mercadopago/subscription/${data.id}`, data);
    },
    delete(id) {
        return Api().delete(`mercadopago/subscription?id=${id}`);
    }
}