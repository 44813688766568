import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`address?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`address/${id}`);
    },
    create(data) {
        return Api().post(`address`, data);
    },
    update(data) {
        return Api().put(`address/${data.id}`, data);
    },
    delete(idAddress){
        return Api().delete(`address/${idAddress}`);
    },
    getViaCep(zip){
        return Api().get(`https://viacep.com.br/ws/${zip}/json/`);
    }
}