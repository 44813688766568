import CardService from '../../services/CardService'

const state = () => ({
    loadingCards: false,
    card: null,
    cards: [],
    show_modal_new_card: false,
    show_modal_edit_card: false
})
  
const mutations = {
    SET_CARD: (state, payload) => {
        state.card = payload
    },
    SET_CARDS: (state, payload) => {
        state.cards = payload
    },
    SET_LOADING_CARDS: (state, payload) => {
        state.loadingCard = payload
    },
    SET_SHOW_MODAL_CARD: (state, payload) => {
        state.show_modal_new_card = payload
    },
    SET_SHOW_MODAL_EDIT_CARD: (state, payload) => {
        state.show_modal_edit_card = payload
    },
}

const actions = {
    async fetchCard({commit}, card){
        try{
            commit('SET_LOADING_CARDS', true)
            const response = await CardService.getOne(card.id, card.customer_id);
            commit('SET_CARD', response.data)
            commit('SET_LOADING_CARDS', false)
        } catch (error) {
            commit('SET_LOADING_CARDS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCards({commit, dispatch}){
        try{
            commit('SET_LOADING_CARDS', true)
            const customer_id = await dispatch('findCustomer_id')
            if(customer_id){
                const response = await CardService.get(customer_id);
                commit('SET_CARDS', response.data.itens)
                commit('SET_LOADING_CARDS', false)
            }
        } catch (error) {
            commit('SET_LOADING_CARDS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCard({commit, dispatch}, data){
        try{
            commit('SET_LOADING_CARDS', true)
            data.customer_id = await dispatch('findCustomer_id')
            if (data.customer_id) {
                await CardService.create(data);
                dispatch('fetchCards')
                commit('SET_SHOW_MODAL_CARD', false)
                commit('SET_LOADING_CARDS', false)
            } 
            commit('SET_LOADING_CARDS', false)
        } catch (error) {
            commit('SET_LOADING_CARDS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCardToken({commit, dispatch}, data){
        try{
            commit('SET_LOADING_CARDS', true)
            data.customer_id = await dispatch('findCustomer_id')
            if (data.customer_id) {
                await CardService.createToken(data);
                dispatch('fetchCards')
                commit('SET_SHOW_MODAL_CARD', false)
                commit('SET_LOADING_CARDS', false)
            } else {
                commit('SET_LOADING_CARDS', false)
                commit('SET_ALERT', {
                  heading: 'info',
                  message: 'Não foi possível encontrar o usuarios no Mercado Pago. Tente novamente.',
                });
            }
        } catch (error) {
            commit('SET_LOADING_CARDS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeCard({commit, state, dispatch}, data){
        try{
            commit('SET_LOADING_CARDS', true)

            data.customer_id = await dispatch('findCustomer_id')
            if (data.customer_id) {
                data.card_id = state.card.id;

                const response = await CardService.update(data);
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
                dispatch('fetchCards')
                commit('SET_LOADING_CARDS', false)
            } else {
                commit('SET_LOADING_CARDS', false)
                commit('SET_ALERT', {
                  heading: 'info',
                  message: 'Não foi possível encontrar o usuarios no Mercado Pago. Tente novamente.',
                });
            }
        } catch (error) {
            commit('SET_LOADING_CARDS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteCard({commit, dispatch}, data){
        try{
            commit('SET_LOADING_CARDS', true)
            const response = await CardService.delete(data.id, data.customer_id);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchCards')
            commit('SET_SHOW_MODAL_CONFIRMATION', false)
            commit('SET_SHOW_MODAL_EDIT_CARD', false)
            commit('SET_LOADING_CARDS', false)
        } catch (error) {
            commit('SET_LOADING_CARDS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getCard: state => state.card,
    getCards: state => state.cards,
    getLoadingCard: state => state.loadingCard,
    getShowModalNewCard: state => state.show_modal_new_card,
    getShowModalEditCard: state => state.show_modal_edit_card
}

export default {
    state,
    getters,
    mutations,
    actions
};