import SubscriptionService from '../../services/SubscriptionService'

const state = () => ({
    loadingSubscriptions: false,
    subscription: null,
    subscriptions: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_subscription: false,
    show_modal_subscription: false
})
  
const mutations = {
    SET_SUBSCRIPTION: (state, payload) => {
        state.subscription = payload
    },
    SET_SUBSCRIPTIONS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.subscriptions = payload.itens
        }else{
            state.subscriptions = [...state.subscriptions, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_SUBSCRIPTIONS: (state, payload) => {
        state.loadingSubscription = payload
    },
    SET_SHOW_MODAL_NEW_SUBSCRIPTION: (state, payload) => {
        state.show_modal_new_subscription = payload
    },
    SET_SHOW_MODAL_SUBSCRIPTION: (state, payload) => {
        state.show_modal_subscription = payload
    },
}

const actions = {
    async fetchMySubscription({commit}){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.getMyCurrent();
            commit('SET_SUBSCRIPTION', response.data.item)
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    // async fetchSubscriptions({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
    //     try{
    //         commit('SET_LOADING_SUBSCRIPTIONS', true)
    //         const response = await SubscriptionService.get(keyword, pageNumber, order);
    //         commit('SET_SUBSCRIPTIONS', response.data)
    //         commit('SET_LOADING_SUBSCRIPTIONS', false)
    //     } catch (error) {
    //         commit('SET_LOADING_SUBSCRIPTIONS', false);
    //         commit('LOG_USER_OUT');
    //         commit('SET_ALERT', {
    //             heading: 'error',
    //             message: error.response.data.message
    //         });
    //     }
    // },
    async createSubscription({commit}, {card, token}){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
             const body = {
                reason: `Plano de Recorrência d${process.env.VUE_APP_ARTIGO} ${process.env.VUE_APP_TITLE}`,
                card_id: card.id,
                customer_id: card.customer_id,
                card_token_id: token,
                back_url: process.env.VUE_APP_NOTIFICATION_URL,
                status: "authorized"
            };
            const response = await SubscriptionService.create(body);
            commit('SET_SUBSCRIPTION', response.data.item)
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_NEW_SUBSCRIPTION', false)
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    // async updadeSubscription({commit}, data){
    //     try{
    //         commit('SET_LOADING_SUBSCRIPTIONS', true)
    //         const response = await SubscriptionService.update(data);
    //         commit('SET_ALERT', {
    //             heading: 'success',
    //             message: response.data.message
    //         });
    //         commit('SET_LOADING_SUBSCRIPTIONS', false)
    //     } catch (error) {
    //         commit('SET_LOADING_SUBSCRIPTIONS', false);
    //         commit('SET_ALERT', {
    //             heading: 'error',
    //             message: error.response.data.message
    //         });
    //     }
    // },
    async deleteSubscription({commit, dispatch}, id){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.delete(id);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchMySubscription')
            commit('SET_SHOW_MODAL_CONFIRMATION', false)
            commit('SET_SHOW_MODAL_SUBSCRIPTION', false)
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getSubscription: state => state.subscription,
    getSubscriptions: state => state.subscriptions,
    getLoadingSubscription: state => state.loadingSubscription,
    getShowModalNewSubscription: state => state.show_modal_new_subscription,
    getShowModalSubscription: state => state.show_modal_subscription
}

export default {
    state,
    getters,
    mutations,
    actions
};