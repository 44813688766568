import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`plan/my?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getPending() {
        return Api().get(`plan/pending`);
    },
    getOne(id) {
        return Api().get(`plan/${id}`);
    },
}