import Api from './Api'

export default {
    getDadosComerciais(){
        return Api().get('socio');
    },
    setDadosComerciais(formData){
        return Api().post('socio/dadosComerciais', formData);
    }
}
