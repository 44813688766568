import { formatarData, formatarDataComHora } from '@/helpers';

export const globalMixin = {
    methods: {
        formatarDataComHora(date) {
            return formatarDataComHora(date);  // Com hora
        },
        formatarData(date) {
            return formatarData(date);  // Sem hora
        }
    }
};