import AuthService from '../../services/AuthService'
// import axios from 'axios'

const state = () => ({
    loadingCropper: false,
    showModelCropper: false
})
  
const mutations = {
    SHOW_MODAL_CROPPER: (state, payload) => {
        state.showModelCropper = payload
    }
}

const actions = {
    async updateUserProfileImage({ commit },  { image }) {
        try {
            commit('SHOW_MODAL_CROPPER', true)
            const response = await AuthService.updateProfileImage(image);
            commit('SET_USER_PROFILE_IMAGE', response.data.image);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            if(response.data.restart){
                setTimeout(() => {
                    location.reload();
                }, 1000);
            }
            commit('SHOW_MODAL_CROPPER', false)
        } catch (error) {
            commit('SHOW_MODAL_CROPPER', false)
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
      }
}

const getters = {
    getShowModalCropper: state => state.showModelCropper,
    getLoadingCropper: state => state.loadingCropper
}

export default {
    state,
    getters,
    mutations,
    actions
};