import Api from './Api'

export default {
    getOneByTransaction_id(transaction_id){
        return Api().get(`payment/transaction_id/${transaction_id}`);
    },
    getValuePayment(data){
        return Api().post(`payment/value_to_pay`, data);
    },
    createPaymentPix(data){
        return Api().post(`mercadopago/payment/process_payment_pix`, data);
    },
    createPaymentCard(data){
        return Api().post(`mercadopago/payment/process_payment`, data);
    },
    verifyPayment(transaction_id){
        return Api().post(`mercadopago/payment/verifyPayment`, { transaction_id });
    },
    setWebhookPayment(body){
        return Api().post(`mercadopago/webhook`, body);
    },
    savePaymentCredit(data){
        return Api().post('payment/add/credit', data);
    },
    savePaymentPix(data){
        return Api().post('payment/add/pix', data);
    },
}