import Api from './Api'

export default {
    getAll() {
        return Api().get(`category/myCategories`);
    },
    getAllByPeriods() {
        return Api().get(`category/myCategoriesByPeriods`);
    },
    getOne(slug) {
        return Api().get(`category/${slug}`);
    }
}